import React from "react";
import { NavLink } from "react-router-dom";
import "../../css/MobileNav.css";

export default function MobileNav({ openDropdown }) {
  return (
    <div id="mobile-menu" className="visible md:hidden">
      <div id="mobile-dropdown-item">
        <div id="mobile-option-item">
          <NavLink to="/">Homepage</NavLink>
        </div>
      </div>
      <div id="mobile-dropdown-item2">
        <div id="mobile-option-item">
          <NavLink to="/about">About</NavLink>
        </div>
      </div>
      {/* <div id="mobile-dropdown-item2">
        <div id="mobile-option-item">
          <NavLink to="/LampapuyNFTs">NFT Marketplace</NavLink>
        </div>
      </div> */}
      {/* <div id="mobile-dropdown-item2">
        <div id="mobile-option-item">
          <NavLink to="/10KLampaNFTs">10K Lampa NFT</NavLink>
        </div>
      </div> */}
      {/* <div id="mobile-dropdown-item2">
        <div id="mobile-option-item">
          <NavLink to="https://bictory.domains/SolNames" target="_blank">
            LPP Domains
          </NavLink>
        </div>
      </div> */}
      <div id="mobile-dropdown-item2">
        <div id="mobile-option-item">
          <NavLink to="/lppart">$LPP Art</NavLink>
        </div>
      </div>
      <div id="mobile-dropdown-item2" onClick={openDropdown}>
        <svg
          id="chevron-left"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9912 18L8.99121 12L14.9912 6"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div id="mobile-option-item">Resource</div>
      </div>
    </div>
  );
}
