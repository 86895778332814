import React, { useState, useEffect } from "react";
import "../App.css";
import Pdf1 from "../documents/whitepaper.pdf";

function Footer() {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);

  const apiKey =
    "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";

  const handleSubscribe = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://api.brevo.com/v3/contacts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": apiKey,
        },
        body: JSON.stringify({
          email: email,
          listIds: [2],
          attributes: {
            action: "subscribe",
          },
        }),
      });

      if (response.ok) {
        setShowModal(true); // Show the modal when subscription is successful
        console.log("contact added!", email);
      }
    } catch (error) {
      console.error("Error subscribing:", error);
    }
  };

  const handleInput = (e) => {
    setEmail(e.target.value);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <footer id="footer" className="overflow-hidden">
      {showModal && (
        <div className="w-full h-full fixed top-0 left-0 flex items-center justify-center font-inter z-40 modal">
          <div className="w-9/12 md:w-4/12 p-7 justify-center gap-2 md:gap-4 items-center flex flex-col modal-card">
            <p className="text-xl">Thanks for subscribing!</p>
            <button
              className="modal-button w-full font-semibold md:w-2/3 text-black py-2 px-3"
              onClick={closeModal}
            >
              Continue
            </button>
          </div>
        </div>
      )}
      <div
        id="footer-top"
        className="flex flex-col md:flex-row md:justify-between"
      >
        <div id="footer-left">
          <img
            id="footer-logo"
            src="./assets/images/lampapuy-logo.svg"
            alt="LAMPAPUY logo"
          />
          <p id="footer-headline">
            The first Community Coin of the Solana Ecosystem
          </p>
          <p id="sub-footer-headline">
            We strive to build Community-Oriented Projects around the $LPP Coin.
          </p>
          {/* <h3 id="footer-contact-us">For Marketing</h3>
          <div id="contact-flex" className="gap-2">
            <img
              src="./assets/images/email-icon.svg"
              alt="email icon"
              id="email-icon"
            />
            <a
              className="hover:underline"
              href="mailto:marketing@bictory.io"
              target="_blank"
              rel="noreferrer"
              id="footer-email-address"
            >
              marketing@bictory.io
            </a>
          </div>
          <h3 id="footer-contact-us-2">For Business</h3>
          <div id="contact-flex" className="gap-2">
            <img
              src="./assets/images/email-icon.svg"
              alt="email icon"
              id="email-icon"
            />
            <a
              className="hover:underline"
              href="mailto:business@bictory.io"
              target="_blank"
              rel="noreferrer"
              id="footer-email-address"
            >
              business@bictory.io
            </a>
          </div> */}
        </div>
        <div
          id="footer-right"
          className="flex flex-col items-center justify-center md:mt-12 relative md:ml-24"
        >
          <img
            id="you-better-subscribe-image"
            src="./assets/images/subscribe.svg"
            alt="you better subscribe"
            className="self-center absolute"
          />
          <form
            className="flex border md:mb-10 md:mt-44"
            id="footer-email-holder"
            onSubmit={handleSubscribe}
          >
            <input
              id="footer-email"
              type="email"
              name="email"
              value={email}
              className="p-4 md:mr-0 text-gray-800"
              placeholder="your@mail.com"
              onChange={handleInput}
            />
            <button
              type="submit"
              id="footer-email-button"
              className="md:px-8 md:m-2 text-gray-800 font-bold md:p-4 uppercase md:border-t border-b border-r"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>
      <div
        id="footer-bottom"
        className="flex flex-col-reverse items-center md:flex-row md:justify-between"
      >
        <div id="copyright">
          <p id="copyright-text" className="text-base">
            All Rights Reseved Lampapuy © 2024
          </p>
        </div>
        <div id="footer-social-icons" className="flex md:flex-row">
          <a target="_blank" rel="noreferrer" href="https://lampapuy.com/">
            <img
              src="./assets/images/website-icon.svg"
              alt="website icon"
              id="footer-social-icon"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://warpcast.com/lampapuy"
          >
            <img
              src="./assets/images/warpcast.svg"
              alt="warpcast icon"
              id="footer-social-icon"
            />
          </a>
          <a target="_blank" rel="noreferrer" href="https://t.me/lampapuy">
            <img
              src="./assets/images/telegram-icon.svg"
              alt="telegram icon"
              id="footer-social-icon"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://x.com/LampaCommunity"
          >
            <img
              src="./assets/images/twitter-icon.svg"
              alt="twitter icon"
              id="footer-social-icon"
            />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://discord.gg/kucK33WG6k"
          >
            <img
              src="./assets/images/discord-icon.svg"
              alt="discord icon"
              id="footer-social-icon"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://medium.com/@lampapuy"
          >
            <img
              src="./assets/images/m-icon.svg"
              alt="m icon"
              id="footer-social-icon"
            />
          </a>
          {/* <a target="_blank" rel="noreferrer" href={Pdf1}>
            <img
              src="./assets/images/file-icon.svg"
              alt="file icon"
              id="footer-social-icon"
            />
          </a> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
