import Roadmap from "./subComponents.js/Roadmap";
import Pdf from "../documents/whitepaper.pdf";
import MobileRoadmap from "./subComponents.js/MobileRoadmap";
import Listing from "./subComponents.js/Listing";

export default function Hero() {
  return (
    <div className="overflow-hidden">
      <div className="relative">
        <div className="relative flex justify-center">
          <img
            src="./assets/images/new-bg.png"
            className="w-full"
            id="homepage-bg-image"
            alt=""
          />
          <div className="absolute flex flex-col items-center">
            <div
              id="hero-text-container"
              className="relative flex justify-center"
            >
              <h1 className="text-center absolute z-10" id="home-banner-text1">
                <span id="lpp-word">Lampapuy:</span> The <br />
                Joyful Heartbeat of <br />
                the <span id="sol-word">Solana</span> <br />
                Community!
              </h1>
              <h1 className="text-center " id="home-banner-text2">
                <span id="lpp-word">Lampapuy:</span> The <br />
                Joyful Heartbeat of <br />
                the <span id="sol-word">Solana</span> <br />
                Community!
              </h1>
            </div>

            <div className="md:flex items-center flex-col hidden mt-8 relative py-5">
              <div
                className="-top-2 md:px-4 md:py-2 text-xs p-2 md:text-lg font-black absolute z-10"
                id="lpp-address-header"
              >
                $LPP Contract Address
              </div>
              <div
                className="md:py-4 md:text-2xl font-normal text-xs p-3 md:px-6 rounded-lg"
                id="lpp-address"
              >
                J7dsB6BAoBR6cMeT2Pxt1MtB2t4EJ24pRbT1uxkUBsKP
              </div>
            </div>
            <div className="flex items-center flex-row justify-center py-6 gap-2 md:gap-4">
              <a
                href="https://www.coingecko.com/en/coins/lampapuy"
                target="_blank"
                rel="noreferrer"
                className="z-40"
              >
                <img
                  src="./assets/images/coingecko.png"
                  className="h-3 md:h-10"
                  alt="dexscreener"
                />
              </a>
              <a
                href="https://dexscreener.com/solana/9yrdc71u4oojaw57ttuzmsjmyesp61damgkxmxvhlspz"
                target="_blank"
                rel="noreferrer"
                className="z-40 flex flex-row gap-1"
              >
                <img
                  src="./assets/images/dexscreener.png"
                  className="h-3 md:h-8"
                  alt="dexscreener"
                />
              </a>
              <a
                href="https://www.cyberscope.io/audits/1-lpp"
                target="_blank"
                rel="noreferrer"
                className="z-40"
              >
                <img
                  src="./assets/images/cyberscope.png"
                  className="h-3 md:h-7"
                  alt=""
                />
              </a>
              <a
                href="https://bictory.foundation/"
                target="_blank"
                rel="noreferrer"
                className="z-40"
              >
                <img
                  src="./assets/images/bictory-partnership.png"
                  className="h-3 md:h-14"
                  alt=""
                />
              </a>

              {/* <a
                href="https://assuredefi.com/projects/lampapuy-dao"
                target="_blank"
                rel="noreferrer"
                className="z-40"
              >
                <img
                  src="https://assuredefi.com/wp-content/uploads/2023/03/assure-banners-seal-yellow-transparent.png"
                  target="_blank"
                  alt="Proudly Verified By Assure DeFi™"
                  className="md:w-40 w-8"
                />
              </a> */}
            </div>
          </div>

          <div
            id="ellipse-image-container"
            className="flex justify-end absolute"
          >
            <img id="ellipse-image" src="./assets/images/Ellipse2.png" alt="" />
          </div>
          <div className="absolute z-10" id="lpp-banner">
            <div id="lpp-banner-inner" className="flex flex-row items-center">
              <div className="banner-element">
                <img
                  src="./assets/images/goldLpp.png"
                  alt=""
                  id="banner-image"
                />
              </div>
              <div id="banner-text" className="banner-element">
                LAMPAPUY
              </div>
              <div className="banner-element">
                <img
                  src="./assets/images/silverLpp.png"
                  alt=""
                  id="banner-image"
                />
              </div>
              <div id="banner-text" className="banner-element">
                LAMPAPUY
              </div>
              <div className="banner-element">
                <img
                  src="./assets/images/goldLpp.png"
                  alt=""
                  id="banner-image"
                />
              </div>
              <div id="banner-text" className="banner-element">
                LAMPAPUY
              </div>
              <div className="banner-element">
                <img
                  src="./assets/images/silverLpp.png"
                  alt=""
                  id="banner-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center relative">
        <Listing />
      </div>
      <div className="md:my-56 my-12 mb-16 flex justify-center">
        <img
          id="homepage-spec-image"
          src="./assets/images/specification.png"
          alt=""
        />
      </div>
      {/* <div className="md:my-56 flex justify-center flex-col relative">
        <div id="allocation-header-frame" className="self-center absolute z-10">
          <h1 id="coin-allocation-text">$LPP Coin Allocation</h1>
        </div>
        <div
          id="allocation-content-frame"
          className="self-center flex justify-center flex-col"
        >
          <img
            src="./assets/images/analytics.png"
            className="self-center"
            alt=""
            id="analytics-img"
          />
          <div id="disclaimer-text">
            *Lampapuy DAO will raise initial funding (for development) by
            selling its portion of allocation. <br />
          </div>
        </div>
      </div> */}
      {/* <div className="md:my-32 flex md:justify-center  flex-col md:items-center">
        <h3 id="roadmap-text" className="">
          Road Map
        </h3>
        <Roadmap />
        <MobileRoadmap />
        <div id="roadmap-disclaimer" className="visible md:hidden">
          <p className="text-white">
            *This Roadmap is a preliminary document and is subject to change as
            Lampapuy DAO continues to evolve and adapt to market needs and
            opportunities.
          </p>
        </div>
      </div> */}
    </div>
  );
}
